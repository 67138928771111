<template>
  <div class="bg-center bg-no-repeat bg-cover bg-banner">
    <div class="container relative">
      <div class="lg:flex">
        <div class="pt-20 text-center lg:text-left 2xl:w-1/2">
          <div class="xl:pt-14">
            <h2 class="pb-2 text-2xl font-semibold text-gray-800 md:text-3xl">
              Enjoy Straighter Teeth With
              <span class="text-blue-400">INVISALIGN </span>
            </h2>
            <h2 class="text-4xl font-semibold text-gray-800 md:text-5xl">
              From As Low As <br class="hidden lg:block"><span class="text-green-1">RM25</span> Per Day
            </h2>
            <h4
              class="pt-3 text-lg font-bold leading-tight text-gray-800 md:text-xl"
            >
            Experts In Clear Aligners And Cosmetics Dentistry
            </h4>
            <div v-if="!hideEnquiry" class="pt-6 md:block md:pt-6">
              <h1
                class="py-2 mx-auto text-center text-white transition duration-200 ease-in-out transform w-60 lg:mx-0 md:mx-auto bg-green-1 hover:scale-110 rounded-tr-xl rounded-bl-xl"
              >
                <a href="https://wa.me/60163490668">Book An Appointment Now</a>
              </h1>
            </div>
            <img
              src="/images/invisalign-logo.jpg"
              alt=""
              class="hidden w-40 pt-3 mx-auto md:w-52 md:block lg:mx-0"
            />
          </div>
        </div>
        <div class="md:w-1/2 md:mx-auto xl:w-1/3">
          <img src="/images/bg-img.png" alt="" class="w-full" />
        </div>
      </div>
      <div class="absolute md:hidden bottom-4 right-4">
        <img
          src="/images/invisalign-logo.jpg"
          alt=""
          class="w-40 pt-3 mx-auto lg:mx-0"
        />
        <!-- <h1
          class="py-2 text-center text-white transition duration-200 ease-in-out transform w-60 bg-green-1 hover:scale-110 rounded-tr-xl rounded-bl-xl"
        >
          <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
            >Book An Appointment Now</a
          >
        </h1> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
