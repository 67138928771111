<template>
  <div>
    <WaBtn />
    <!-- header  -->
    <div class="h-1 bg-blue-1"></div>
    <div class="py-5">
      <div class="container">
        <div class="flex items-center justify-between">
          <img src="/images/main-logo.png" alt="" class="w-52 md:w-64" />
          <div class="transition duration-200 ease-in-out transform md:hidden hover:scale-125">
            <a href="tel:+60163490668">
              <img src="/images/phone.png" alt="" class="w-10" />
            </a>
          </div>
          <div class="hidden md:block">
            <h1
              class="w-40 py-2 text-lg text-center text-white transition duration-300 ease-in-out transform rounded-md hover:scale-110 bg-blue-1">
              <a href="tel:+60163490668">Call Us Now</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- main banner -->
    <MainBanner />

    <!-- invisalign -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <h2 class="text-2xl text-center md:text-3xl text-blue-1">
          What is Clear Aligner For
          <span class="text-green-1">Kids to Adults? </span>
        </h2>
        <div class="bg-blue-1 w-20 h-0.5 mx-auto my-4"></div>

        <div class="space-y-2 text-sm text-center text-gray-600">
          <p>
            Clear Aligner is a stylish ‘invisible’ alternative to the
            conventional metal braces. Through careful treatment planning, we
            will prepare a set of clear aligners that gradually move your teeth.
          </p>
          <p>
            You are able to enjoy your favourite foods, drinks and social events
            with minimal disruption to your life.
          </p>
        </div>

        <div class="pt-6 md:flex md:flex-wrap">
          <div v-for="(item, i) in invisalign" :key="i" class="pb-3 lg:w-1/3 md:px-1 md:pb-6 md:w-1/2">
            <img :src="item.image" :alt="item.alt" class="mx-auto rounded-lg" />
            <div class="pt-2 text-center">
              <h2 class="pb-2 text-lg font-semibold text-green-1">
                {{ item.alt }}
              </h2>
              <p class="text-sm text-gray-600">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- comparison  -->
    <div class="pt-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-compare.jpg')">
      <div class="container">
        <h2 class="text-xl leading-tight text-center text-gray-700 capitalize md:text-3xl">
          Comparison between Clear Aligner and conventional braces
        </h2>

        <div class="pt-8 lg:pt-12 md:flex md:flex-wrap">
          <div v-for="(item, i) in compare" :key="i" :class="item.order"
            class="pb-4 lg:px-1 md:w-3/4 lg:w-1/4 lg:mx-0 md:mx-auto">
            <div
              class="p-5 text-center transition duration-200 ease-in-out transform bg-white rounded-lg hover:scale-105 hover:bg-opacity-50 bg-opacity-30">
              <h2 :class="item.color" class="text-2xl font-bold">
                {{ item.title }}
              </h2>
              <div class="pt-6">
                <p v-for="list in item.sublist" :key="list" class="py-3 text-sm text-gray-600 border-t border-gray-400">
                  {{ list }}
                </p>
              </div>
            </div>
          </div>
          <div class="lg:w-2/4 md:w-2/3 md:mx-auto lg:order-2 lg:block">
            <img src="/images/compare-1.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- treatment work  -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-works.jpg')">
      <div class="container">
        <h2 class="text-xl leading-tight text-center text-blue-1 md:text-3xl">
          How Clear Aligner Treatment works?
        </h2>

        <div class="flex flex-wrap pt-8 md:justify-center">
          <div v-for="(item, i) in treatment" :key="i" class="w-1/2 px-1 pb-4 lg:px-2 md:w-1/3 lg:w-1/4">
            <div class="p-6 mx-auto bg-white rounded-full w-28 h-28">
              <img :src="item.image" :alt="item.alt" class="" />
            </div>
            <div class="pt-4 text-xs text-center md:text-sm">
              <p class="text-gray-600">{{ item.p }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- subbanner  -->
    <div class="py-10 bg-green-3">
      <div class="container">
        <div>
          <h1 class="text-4xl font-bold leading-tight text-center text-gray-800 capitalize md:text-5xl">
            Smile big, smile confidently
          </h1>
          <div class="pt-4 space-y-2 text-sm leading-tight text-center text-gray-600 md:text-base">
            <p>
              Clear Aligner is ideal for people of all ages - kids, teens and
              adults.
            </p>
            <p>Myobrace for kids is available.</p>
            <p>
              We are committed to helping you and your kids to
              achieve your perfect smile easily and comfortably.
            </p>
          </div>
          <div class="pt-5">
            <p
              class="py-2 mx-auto text-center text-gray-600 transition duration-200 ease-in-out transform rounded-md hover:text-white hover:scale-105 hover:bg-blue-1 w-52 bg-blue-2">
              <a href="https://wa.me/60163490668">Enquire Now</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h2 class="text-2xl text-center md:text-3xl text-blue-1">
          Why Choose Metro Perdana Dental As <br class="hidden lg:block" /><span class="text-green-1">Your Clear Aligner
            Provider</span>
        </h2>
        <div class="bg-blue-1 w-20 h-0.5 mx-auto my-4"></div>

        <div class="pt-5 md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div v-for="(item, i) in choose_us" :key="i" class="w-2/3 pb-2 mx-auto md:mx-0 md:w-1/3 md:p-1">
            <div
              class="px-4 py-8 transition duration-200 ease-in-out transform bg-white rounded-md shadow-md hover:scale-105">
              <div class="p-5 mx-auto rounded-full w-28 h-28 bg-green-3">
                <img :src="item.image" :alt="item.alt" class="w-20 m-auto" />
              </div>
              <div class="pt-3 text-center">
                <h1 class="leading-tight text-gray-600 md:text-sm md:leading-tight">
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 bg-blue-50">
      <div class="container">
        <h2 class="text-2xl text-center md:text-3xl text-blue-1">
          Our <span class="text-green-1">Doctors</span>
        </h2>
        <div class="bg-blue-1 w-20 h-0.5 mx-auto my-4"></div>
      </div>
    </div>

    <!-- our doctor -->
    <div class="py-10">
      <div class="container">
        <div class="pt-5">
          <div v-for="(item, i) in doctor" :key="i" class="mb-5 md:pb-10 2xl:max-w-6xl 2xl:mx-auto">
            <div class="md:flex md:items-center">
              <div class="md:w-1/2" :class="item.order">
                <img :src="item.image" :alt="item.alt" class="mx-auto w-60 md:w-4/5 2xl:w-1/2" />
              </div>
              <div class="md:w-1/2">
                <div class="p-4 mt-2 text-center transition duration-300 rounded-lg bg-green-2 hover:shadow-md">
                  <h2 class="text-xl font-medium text-gray-700">
                    {{ item.alt }}
                  </h2>
                  <p v-html="text" v-for="text in item.p" :key="text"
                    class="mt-3 text-sm leading-tight text-left text-gray-600">
                  </p>
                  <ul v-show="item.point" class="pl-6 mt-2 text-sm text-left text-gray-600 list-disc list-outside">
                    <li v-for="list in item.point" :key="list">{{ list }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- product and services  -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-services.jpg')">
      <div class="container">
        <div>
          <div class="text-center">
            <h2 class="text-2xl font-medium leading-tight text-gray-600 md:text-3xl">
              Our
              <span class="text-gray-600">Services</span>
            </h2>
            <div class="bg-blue-1 w-20 h-0.5 mx-auto my-4"></div>
          </div>
          <div class="mt-8 md:flex md:flex-wrap md:justify-center">
            <div v-for="(item, i) in services" :key="i"
              class="w-4/5 mx-auto mb-3 md:mx-0 lg:mx-0 md:p-1 md:w-1/3 lg:w-1/4">
              <div
                class="text-gray-600 transition duration-200 ease-linear transform bg-white rounded-lg hover:scale-105 hover:text-white hover:bg-green-2">
                <img :src="item.image" alt="" class="rounded-t-md" />
                <div class="px-3 py-5 text-base leading-tight text-center">
                  <p>
                    {{ item.p }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <h1
              class="py-2 mx-auto font-medium text-center text-white transition duration-200 ease-linear transform rounded w-60 hover:scale-105 bg-green-2">
              <a href="https://wa.me/60163490668">Book Your Appointment Now</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="py-10 bg-white">
      <div class="container">
        <h2 class="text-2xl text-center md:text-3xl text-blue-1">
          Our <span class="text-green-1">Testimonials</span>
        </h2>
        <div class="bg-blue-1 w-20 h-0.5 mx-auto my-4"></div>
        <div>
          <carousel :autoplay="true" :loop="true" :responsive="{
            0: { items: 1, nav: false, dots: true },
          }">
            <div v-for="(item, i) in testimonial" :key="i" class="pt-10 text-center">
              <p class="text-sm font-normal leading-tight text-gray-600">
                {{ item.text }}
              </p>
              <h1 class="pt-6 text-lg font-bold text-green-1">
                {{ item.client }}
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div id="element" class="relative z-0 py-12 text-center text-white bg-blue-3 lg:py-20 enquiry-bottom">
      <div class="absolute top-0 w-full py-24 bg-center bg-cover"
        style="background-image: url('/images/bg-enquiry.jpg')">
        <div class="py-20 lg:py-12"></div>
      </div>
      <div class="relative z-40 max-w-screen-xl px-4 mx-auto">
        <h2 class="mb-8 text-2xl font-semibold text-white md:text-3xl lg:mb-12">
          Book your Free Consultation <br class="md:hidden" />with us Today!
        </h2>
        <div class="w-full p-8 mb-8 bg-white rounded lg:mb-12">
          <EnquiryForm />
        </div>
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2 lg:pr-4">
            <img src="/images/main-logo.png" alt="" class="mx-auto w-60 md:w-80" />
            <div class="pt-3 text-gray-700">
              <h1 class="text-lg font-medium">
                3-2-2, Jalan Setia Prima(A), U13/A, Seksyen U13, Setia Alam,
                40170 Shah Alam, Selangor
              </h1>
              <div
                class="pt-2 font-medium transition duration-200 ease-in-out transform hover:text-green-2 hover:scale-110">
                <p>Call Us At <a href="tel:+60163490668">016-349 0668</a></p>
              </div>
            </div>
          </div>
          <div class="pt-4 lg:pt-0 lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.981500790803!2d101.44186125050626!3d3.0995750977318663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b2ff2f3339%3A0xbae1a9592e77fc83!2sSmile%20Quest%20Dental%20Clinic!5e0!3m2!1sen!2smy!4v1664425656642!5m2!1sen!2smy"
              width="100%" height="300" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="py-3 bg-white">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-gray-700 lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    carousel,
    EnquiryForm,
    MainBanner,
    WaBtn,
  },
  data() {
    return {
      services: [
        { image: "/images/service-1.jpg", p: "Clear Aligner" },
        { image: "/images/service-2.jpg", p: "Veneer" },
        { image: "/images/service-3.jpg", p: "Oral Care for Children" },
        { image: "/images/service-4.jpg", p: "Scaling and Polishing" },
        { image: "/images/service-5.jpg", p: "Dental Fillings" },
        { image: "/images/service-6.jpg", p: "Teeth Whitening" },
        { image: "/images/service-8.jpg", p: "Root Canal Treatment" },
        { image: "/images/service-9.jpg", p: "Crowns and Bridges" },
        { image: "/images/service-10.jpg", p: "Dental Implant" },
        { image: "/images/service-11.jpg", p: "Denture" },
        { image: "/images/service-12.jpg", p: "Wisdom Teeth Removal" },
        { image: "/images/service-13.jpg", p: "Myobrace For Kids" },
      ],
      doctor: [
        {
          image: "/images/doc-1.png",
          alt: "Dr. Suet Lynn",
          p: [
            "Dr. Suet Lynn was graduated from Aimst University. She’s a passionate and friendly dentist with 7 years of clinical practice experience. She was well trained in cosmetic dentistry in the previous years and she’s also good in calming patients with dental phobia. <br><br>Interested in aesthetic and restorative dentistry.",
          ],
          point: [
            "BDS (Aimst)",
            "Postgraduate Implantology Training Course (UM)",
            "Minor Oral Surgery course (Malaysia)",
            "Invisalign training course (US) ongoing",
            "PG Diploma in Orthodontics & Dentofacial (London Dental Institute) ongoing",
            "Master class in Veneering by Dental Academy",
            "Postgraduate Aesthetic Medicine training course by International Institute of Aesthetic Medicine (London)",
            "Certified CBCT user ",
            "Certified lazon laser user",
          ],
        },
        // {
        //   order: "order-2",
        //   image: "/images/doc-2.png",
        //   alt: "Dr. Ho Khoon Meng",
        //   point: [
        //     "Dr. Ho Khoon Meng is currently in Private Practice with 4 years of experience.",
        //     "He completed the McGann Post Graduate School of Dentistry Programme (USA) Comprehensive Dental Implantology in Malaysia in the year 2019. ",
        //     "He completed Master Course – Director Workshop at Osstem Implant Malaysia Training Center in Jan 2020.",
        //     "He obtained the Fellowship in International Congress of Oral Implantology (USA) in July 2022.",
        //     "Currently pursuing Master of Science in Implantology (Germany) 2022-2023.",
        //     "He is also the Honorary Secretary General of Osseointegration Implant Society of Malaysia 2022-2023.",
        //     "He has done various procedures in implant dentistry which includes guided bone regeneration, soft tissue augmentation, crestal and lateral sinus lift procedures, ridge split & bone expansion as well as fabrication of guided surgical templates utilizing Blue Sky Bio Software with a fully guided surgical protocol.",
        //   ],
        // },
      ],
      choose_us: [
        {
          image: "/images/icon-1.png",
          alt: "Transparent Pricing, No Hidden Fees",
        },
        {
          image: "/images/icon-2.png",
          alt: "Dedicated Dentist For You",
        },
        {
          image: "/images/icon-3.png",
          alt: "Digital Dental Imaging Technology ",
        },
      ],
      testimonial: [
        {
          text: "I seldom give google review but I’m truly happy with the services provided by Dr Suet Lynn. She is very attentive and she gave me a very detailed explanation on my gum status which my previous dentist did not give. Now my gum is no longer bleeding and sensitivity reduced. Thank you, Dr!",
          client: "JS Tan ",
        },
        {
          text: "Dr. Suet Lynn is very attentive and caring doctor. She patiently explained to me every detail of my oral & teeth one by one :) I get to know so much more about my own oral health after first visit. Will definitely come back for the next visit!",
          client: "Emily Ng Nee",
        },
        {
          text: "Did scaling and whitening here, procedure is quick with minimal discomfort. Dr.Suet is friendly and attentive. She takes time to explain the procedures and checked in periodically to make sure the procedure does not cause too much discomfort.",
          client: "Daniel Chan",
        },
        {
          text: "Clean and nice. Very gentle and friendly doctor . Tq",
          client: "Audris",
        },
        {
          text: "Clean professional and pretty doctor. Amazing receptionist to boot.",
          client: "Desmond",
        },
        {
          text: "Professional services provided by Dr. Suet Lynn, highly recommended!",
          client: "munkit17",
        },
        {
          text: "one off the best clinic. Klinik cantik dan selesa sangat. Staff dan doctor sangat friendly. will be coming back for another treatment ",
          client: "Nurzila Kardi",
        },
        {
          text: "Staff dan dentist sangat friendly. Servis yang diberikan sangat memuaskan. Ruang menunggu very comfort. Thank you smile quest",
          client: "SITI NURUL SYAHIRAH NOR' AZEME",
        },
      ],
      technology: [
        {
          image: "/images/tech-1.jpg",
          alt: "Planmeca ProMax® 3D Classic",
          p: "Capture a clear view of the mandible and maxilla",
          p2: "Our Planmeca ProMax® 3D Classic unit covers the entire dentition – making it an excellent option for full arch dental 3D imaging needs.",
          list: [
            "Extended Volume Size",
            "Movement Artifact Correction",
            "See Even The Finest Details",
            "Ultra Low Dose CBCT Imaging",
            "Imaging Options For All Needs",
            "Flexible SCARA Technology",
            "Excellent Usability",
            "3D Model Scans Efficiently",
            "All-in-one Imaging Software",
            "Ideal for Imaging Braces",
          ],
        },
        {
          image: "/images/tech-2.jpg",
          alt: "Solase Pro Dental Diode Laser",
          p: "SOLASE Pro can emit three wavelengths of laser from a single device.",
          p2: "Each wavelength has better curative effect on specific indication",
          list: [
            "Focus On Details Strive For Perfection",
            "Intelligent System Technology Future",
            "Engineered for Durability Beyond Standard ",
          ],
        },
        {
          image: "/images/tech-3.jpg",
          alt: "EMS Airflow Polishing",
          p: "No Pain, No Stains, Restoring Confident Smiles",
          p2: '"Airflow improves the longevity of dental prosthesis and implants, and preserves cosmetic teeth whitening, too"',
        },
        {
          image: "/images/tech-4.jpg",
          alt: "3 SHAPE TRIOS",
          p: "The wireless intraoral scanner also includes a new generation of TRIOS smart tips that feature instant-heat technology for optimized scanning.",
          p2: "All TRIOS models feature an open workflow ecosystem and partner-integrations with orthodontic, implant-makers, practice management, and sleep treatment providers, as well as manufacturing options with 3D printers and milling machines",
        },
      ],
      invisalign: [
        {
          image: "/images/invisalign-1.jpg",
          alt: "Customized Just For You",
          p: "Clear Aligner is 100% customizable! Our dentist will customise a treatment plan and design your aligned to correct your unique bite and teeth problems.",
        },
        {
          image: "/images/invisalign-2.jpg",
          alt: "Avoid Dental Extractions",
          p: "Clear Aligner is designed to efficiently and gently create space to straighten teeth and solve your bite problems.",
        },
        {
          image: "/images/invisalign-3.jpg",
          alt: "Fewer Appointments",
          p: "Adults are busy people. The fewer appointments, the better! Clear Aligner in-clinic appointments can be stretched to 2-3 months, without compromising quality of care.",
        },
      ],
      benefit: [
        {
          h2: "1",
          p: "Clear and transparent Invisalign aligners can hardly be seen! Hide the braces, Not your smile!",
        },
        {
          h2: "2",
          p: "Invisalign aligners are totally removable. This gives you the freedom to enjoy that hot date or nail that important presentation!",
        },
        {
          h2: "3",
          p: "Thin and flexible Invisalign aligners do not rub against lips and cheeks. No more painful ulcers!",
        },
        {
          h2: "4",
          p: "Invisalign force levels are designed and calculated to be low and evenly distributed. No more achy teeth!",
        },
      ],
      compare: [
        {
          order: "lg:order-1",
          color: "text-green-1",
          title: "Clear Aligner",
          sublist: [
            "Clear aligners are virtually invisible",
            "Smooth comfortable plastic aligners",
            "Aligners are removable for easy cleaning",
            "Eat whatever you want",
            "Treatment time as short as 2 months",
          ],
        },
        {
          order: "lg:order-3",
          color: "text-red-700",
          title: "Braces",
          sublist: [
            "Requires brackets and wires",
            "Wires can poke and irritate mouth",
            "Brushing and flossing require more effort",
            "Many food restrictions",
            "Longer treatment time",
          ],
        },
      ],
      treatment: [
        {
          image: "/images/treat-1.png",
          p: "1. Discuss your treatment goals with your dentist.",
        },
        {
          image: "/images/treat-2.png",
          p: "2. X-rays and dental records are taken to aid our dentist in coming up with a treatment plan tailored to your unique needs",
        },
        {
          image: "/images/treat-3.png",
          p: "3. 3D scanning of your teeth.",
        },
        {
          image: "/images/treat-4.png",
          p: "4. View video simulation of your detailed treatment. You will be able to visualise how your teeth look before, during and after your treatment.",
        },
        {
          image: "/images/treat-5.png",
          p: "5. Receive your brand new Clear Aligner",
        },
        {
          image: "/images/treat-6.png",
          p: "6. Aim to wear your aligners 20-22 hours a day and change them according to schedule.",
        },
        {
          image: "/images/treat-7.png",
          p: "7. Come back for regular visits so that our dentists are able to keep a detailed record of your progress.",
        },
        {
          image: "/images/treat-8.png",
          p: "8. Maintain your new and improved smile!",
        },
      ],
      tips: [
        {
          p: "Wear Your Invisalign Consistently for 20-22 hours a day, including sleeping time",
          image: "/images/tips-1.png",
        },
        {
          p: "Clean your invisalign aligners daily by soaking in a recommended cleaner, to get rid of bacteria and odor",
          image: "/images/tips-2.png",
        },
        {
          p: "Don't eat with your invisalign on and always clean your teeth after every meal, before wearing your invisaligns again",
          image: "/images/tips-3.png",
        },
        {
          p: "Bite firmly on your chewies with your invisaligns on, as often as you can, on every tooth, to seat your invisaligns snugly",
          image: "/images/tips-4.png",
        },
        {
          p: "Plan time for important invisalign visits for smooth progress, set aside at least one hour for this visit",
          image: "/images/tips-5.png",
        },
        {
          p: "Best to change to a new set of invisalign at night to allow your teeth adapts to it uninteruppted",
          image: "/images/tips-6.png",
        },
        {
          p: "Be prepared for changes to your teeth positions and practice speaking",
          image: "/images/tips-7.png",
        },
        {
          p: "do not throw away your old sets of invisalign when you are under treatment",
          image: "/images/tips-8.png",
        },
        {
          p: "do not put your invisaligns near your pet puppy who may chew it",
          image: "/images/tips-9.png",
        },
        {
          p: "share your planned big events with us, we can remove your invisalign attachments temporarily for your important close-up photo shoots",
          image: "/images/tips-10.png",
        },
        {
          p: "Keep positive and upbeat during treatment",
          image: "/images/tips-11.png",
        },
        {
          p: "remember to wear your retainers after your invisalign treatment and come back for regular checkups to maintain that beautiful smile!",
          image: "/images/tips-12.png",
        },
      ],
    };
  },
};
</script>

<style>
.bg-banner {
  /* background-image: url('/images/bg-banner-m.jpg'); */
  background-image: url('/public/images/bg-banner-m.jpg')
}

/* .before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  position: absolute;
  left: 0;
  top: 50%;
} */
@media (min-width: 1024px) {
  .bg-banner {
    background-image: url('/public/images/bg-banner.jpg');
  }
}
</style>
